export class Attrattore {
    id?: number;
    name?: string;
    name_en?: string;
    description?: string;
    description_en?: string;
    picture?: string;
    images?: any[];
    url?: string;
    url_en?:string;
    long?: string;
    lat?: string;
    ar?: { title: string; title_en: string, description: string, description_en:string };
    model?: string;

    constructor() {
        this.images = new Array();
    }
}
